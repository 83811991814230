<template>
  <div class="body">
    <div @click="goToUserRecord" class="font-size30 font-colorFFFFFF"
         style="height: var(--92);padding-top: var(--20);text-align: right;padding-right: var(--30);">
      红包记录
    </div>
    <div class="flex_1" style="overflow: auto">
      <van-list v-model=page.loading
                :immediate-check="false"
                :finished=page.finished
                :finished-text=page.finishedText
                @load="getRedPackList()"
                v-if="page.contentList.length > 0">
        <div v-for="(item, index) in page.contentList" :key="index">
          <div class="flex_able itemClass margin-leftAuto margin-rightAuto margin-top18">
            <img class="margin-left20" style="width: var(--96);height: var(--96);"
                 src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/77e30957-eeba-46ff-8b24-b06ad4574285.png"
                 alt="">
            <div class="flex_able_1 flex-directionColumn flex_1"
                 style="padding-right: var(--20);padding-left: var(--20);">
              <div class="oneLine font-color1B1B1B font-size30 font-weightBold line-height42">恭喜！空投你一个数字红包
              </div>
              <div class="flex_1 margin-top2 font-size26 font-color999999 line-height36">快去获取口令获取吧！</div>
              <div @click="copyRedPackNumber(item)" class="margin-top8 line-height34 copyClass">
                <span class="font-size24 font-color666666">红包编号：{{ item.totalRedPacketUuid }}｜ </span>
                <span class="font-size24" style="color:#E4514F;">复制</span>
              </div>
            </div>

            <div @click="goToGrad(item)"
                 class="sureClass flex_center font-size28 font-colorFFFFFF font-weightBold margin-right20">
              立即领取
            </div>

          </div>

        </div>
      </van-list>
      <show-no-data-view v-if="page.contentList.length == 0">
        <div class="font-size30 font-colorFFFFFF">暂无数据</div>
      </show-no-data-view>
    </div>
    <div style="height: var(--120);"></div>
  </div>
</template>

<script>
import {Toast} from "vant";
import {Api} from "@/utils/net/Api";
import ShowNoDataView from "@/components/ShowNoDataView.vue";
import Clipboard from "clipboard";

export default {
  components: {ShowNoDataView},
  data() {
    return {
      page: {
        finished: false,
        pageNo: 1,
        loading: false,
        finishedText: "没有更多了",
        pageSize: 10,
        contentList: [],
      },
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "空投红包"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#091732',
        textColor: '#FFFFFF',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "false",
        statusBarBgColor: "#091732",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.onResume();
  },
  mounted() {
  },
  methods: {
    /*复制编号*/
    copyRedPackNumber(item) {
      let copyString = item.totalRedPacketUuid.toString()
      //复制口令
      let clipboard3 = new Clipboard(".copyClass", {
        text: function () {
          return copyString
        }
      });
      clipboard3.on('success', function () {//复制成功执行的回调，可选
        Toast("复制成功");
      });
      clipboard3.on('error', function () {//复制失败执行的回调，可选
        Toast("复制失败");
      })
    },
    goToUserRecord() {
      this.$router.push({
        path: '/redPackRecord',
        query: {
          user: 1
        }
      });
    },
    goToGrad(item) {
      this.$router.push({
        path: '/gradRedPack',
        query: {
          d: item.totalRedPacketUuid
        }
      });
    },
    onResume() {
      this.page = {
        finished: false,
        pageNo: 1,
        loading: false,
        finishedText: "没有更多了",
        pageSize: 10,
        contentList: [],
      }
      this.getRedPackList()
    },
    /**
     * 待领取红包列表
     */
    getRedPackList() {
      let page = this.page
      //此处用查流水来实现模拟分页
      let data = {
        pageNo: page.pageNo,
        pageSize: page.pageSize,
      }
      Api.listRedPacketByToUser(data).then(res => {
        if (res.code === 100) {
          let resData = res.data.giveRedPacketList,
              list = resData.list;
          if (resData.isLastPage) { // 没有更多了
            page.finished = true;
          }
          page.contentList = page.contentList.concat(this.handleList(list));
          page.pageNo = resData.nextPage;
          page.loading = false;
        } else {
          Toast(res.msg);
        }
      })
    },
    //处理加工列表数据
    handleList(list) {
      // list.forEach(item => {
      //   console.log(item)
      // })
      return list;
    },
  }
}
</script>

<style scoped>
.body {
  background: #E4514F;
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.itemClass {
  width: var(--690);
  height: var(--202);
  border-radius: var(--20);
  background: #fff;
}

.sureClass {
  width: var(--126);
  height: var(--68);
  background: #E4514F;
  border-radius: var(--10);
}

.sendRedPackClass {
  width: var(--618);
  height: var(--104);
  background: #FFFFFF;
  border-radius: var(--10);
}

.copyClass {
}
</style>
